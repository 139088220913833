
.img-view[data-v-c4b24aa4] {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}
.choose-img[data-v-c4b24aa4] {
  background-color: #f5f5f5;
}
.choose-img img[data-v-c4b24aa4] {
  width: 20px;
  height: 20px;
}
.del-content[data-v-c4b24aa4] {
  position: absolute;
  right: -10px;
  top: -9px;
  padding: 4px;
  cursor: pointer;
}
.del-content img[data-v-c4b24aa4] {
  width: 18px;
  height: 18px;
}
