
.pageblock[data-v-6b50d6aa] {
  text-align: right;
  margin-top: 5px;
}
.uploadImg .uploadImages[data-v-6b50d6aa] {
  float: left;
  width: 100px;
  height: 100px;
}
